<template>
  <div id="login-wrapper">
    <v-row no-gutters justify="center" align="center">
      <!-- <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="12"
        md="8"
        class="hidden-xsOnly d-flex justify-center align-center"
        style="height: 100vh"
      >
        <img
          src="@/assets/Login.svg"
          alt="Login Assets"
          style="max-height: 80%"
        />
      </v-col> -->
      <v-col cols="12" sm="12" md="6">
        <div
          flat
          style="min-height: 95vh"
          class="d-flex align-center justify-center"
        >
          <v-form v-model="valid" ref="form" lazy-validation text-center>
            <v-card-text
              class="text-center"
              style="
                padding: 50px 30px 30px;
                background-color: white;
                border-radius: 12px;
              "
            >
              <img
                id="login-logo"
                :src="logo"
                alt="logo"
                style="width: 90px; height: 90px"
                class="mb-6"
              />
              <div
                class="font-weight-bold"
                style="font-size: 45px; margin-bottom: 60px"
              >
                Login
              </div>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.email"
                    v-on:keyup.enter="submit"
                    :rules="[(v) => !!v || 'Email harus diisi']"
                    name="login-email"
                    label="Email"
                    autocomplete="email"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.password"
                    v-on:keyup.enter="submit"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[
                      (v) => !!v || 'Password harus diisi',
                      (v) => v.length > 5 || 'Password minimal 6 karakter',
                    ]"
                    :type="showPassword ? 'text' : 'password'"
                    name="login-password"
                    label="Password"
                    autocomplete="current-password"
                    required
                    outlined
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                    <div class="d-flex">
                      <v-spacer></v-spacer>
                      <a
                        class="primary--text"
                        @click="$router.push('/forgot-password')"
                        >Lupa password?</a
                      >
                    </div>
                  </v-col> -->
                <v-col cols="12" class="d-flex justify-center">
                  <v-btn
                    :loading="loading"
                    color="black"
                    depressed
                    x-large
                    block
                    class="px-8 white--text"
                    @click="submit"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";

export default {
  name: "Login",
  data() {
    return {
      logo,
      showPassword: false,
      valid: false,
      loading: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email;
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("Login", this.form)
          .then((res) => {
            if (res.data.code) {
              this.$router.push("/orders");
            } else {
              this.$store.commit("SET_SNACKBAR", {
                value: true,
                text: res.data.message,
                color: "error",
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#login-wrapper {
  min-width: 100vw;
  min-height: 100vh;
}
</style>
